img {
  width: 100%;
}

.container-section {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 0rem 1.3rem;

}

.section-1 {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/bg-section-1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 840px;
}

.bg-menu-flex {
  background: linear-gradient(180deg, rgba(3, 20, 39, 0.00) 0%, #090E23 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 191px;
  position: absolute;
  bottom: 0;
}

.menu-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  z-index: 1;
}

.menu-flex img {
  width: 288px;
  height: 110px;
}

img.tital-bg-left {
  max-width: 520px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

img.tital-bg-right {
  max-width: 560px;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.tital-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

span.text-tital-bg {
  background: linear-gradient(173deg, #FFF 52.26%, #007AFF 94.79%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  text-shadow: 0px 7.273px 7.273px rgba(0, 0, 0, 0.25);
  font-family: Impact;
  font-style: normal;
  font-weight: 400;
  z-index: 1;
}

span.text-tital-font1 {
  font-size: 161.63px;
  font-weight: 400;
  transform: translate(0px, 50px);
}

span.text-tital-font2 {
  font-size: 242.445px;
  font-weight: 400;
}


.menu-flex-mini {
  display: none;
}

/* section-2 */

.section-2 {
  padding-top: 1rem;
  background: linear-gradient(360deg, #181D3C 0%, #0a0e23 100%);
  position: relative;
}

.section-2-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

img.section-2-img1 {
  width: 470px;
  transform: translate(25px, 0px);
  z-index: 1;
}

.section-2-box-text {
  max-width: 800px;
  width: 100%;
  min-height: 391px;
  height: 100%;
  border-radius: 20px;
  border: 2px solid #ffffff1f;
  background: linear-gradient(124deg, rgba(24, 29, 60, 0.04) -22.38%, rgba(255, 255, 255, 0.08) 70.38%);
  color: #FFF;
  font-size: 18px;
  padding: 2.5rem 4rem;
  transform: translate(-25px, 0px);
  position: relative;
  margin: auto;
}

span.text-tital-section-2 {
  color: #FC0;
  font-size: 56px;
  font-weight: 900;
}

img.section-2-img2 {
  position: absolute;
  top: -6.5rem;
  right: -3rem;
  width: 276px;
  height: 202px;
}

img.section-2-img3 {
  position: absolute;
  width: 270px;
  right: 0rem;
  bottom: -15rem;
}

.section-2-img1-mb,
.section-2-img3-mb {
  display: none;
}

/* section-3 */

.section-3 {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/section-3-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 3rem;
}

.section-3-text-promotion,
.section-4-text-promotion {
  background: linear-gradient(360deg, rgb(24 29 60 / 0%) 0%, #181D3C 100%);
  height: 259px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.section-text-head1 {
  color: #FFDD15;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 12px;
  text-transform: uppercase;
}

span.section-text-head2 {
  color: #FFF;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.promotion-banner.custom-slider .splide__slide {
  margin: 0 2rem;
}

.promotion-banner.custom-slider .splide__slide img {
  transition: transform 0.3s ease;
}

.promotion-banner.custom-slider .splide__slide.is-active img {
  transform: scale(1.3);
}

.promotion-banner.custom-slider .splide__slide img {
  /* width: 90% !important; */
  padding: 3rem;

}

.promotion-banner button.splide__arrow.splide__arrow--prev {
  left: 20%;
  width: 50px;
  height: 50px;
}

.promotion-banner button.splide__arrow.splide__arrow--next {
  right: 20%;
  width: 50px;
  height: 50px;
}

ul.splide__pagination.splide__pagination--ltr {
  display: none;
}

.section-3-promotion {
  margin-top: -2rem;
}

.promotion-banner-mini {
  display: none;
}

.promotion-banner .custom-slider .splide__slide {
  width: calc(50% - 100px) !important;
  margin: auto !important;
}

.promotion-banner .custom-slider .splide__slide.is-active {
  width: calc(50% - 5px) !important;
  margin: auto 1rem !important;
}

/* .promotion-banner-mini .custom-slider .splide__slide {
  margin-right: 10px !important;
  width: calc(50% - 5px) !important;
}

.promotion-banner-mini .custom-slider .splide__slide.is-active {
  margin-right: 10px !important;
  width: calc(50% - 5px) !important;
} */

/* section-4 */
.section-4 {
  margin-bottom: 5rem;
}

.category .category-game,
.category .category-slot,
.category .category-lotto,
.category .category-casino,
.category .category-sport {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100%;
}

/* .category.active .category-game,
.category.active .category-slot,
.category.active .category-lotto,
.category.active .category-casino,
.category.active .category-sport {
  max-width: 105.29px;
} */

.category-game {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/game.svg);

}

.category-slot {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/slot.svg);

}

.category-lotto {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/lotto.svg);
}

.category-casino {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/casino.svg);
}

.category-sport {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/sport.svg);
}

.icon-games {
  max-width: 167px;
  width: 100%;
}

.games-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 44px;
}

.games-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.category {
  background-position: center;
  width: 204px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  cursor: pointer;
  font-size: 24px;
  color: #FFF;
  border-radius: 12px;
  background: var(--Main, linear-gradient(124deg, rgba(24, 29, 60, 0.04) -22.38%, rgba(255, 255, 255, 0.08) 70.38%));
}

.category.active {
  color: #FBE320;
  background: var(--Style, linear-gradient(0deg, rgba(44, 44, 44, 0.30) 0%, rgba(44, 44, 44, 0.30) 100%), linear-gradient(0deg, rgba(0, 117, 255, 0.80) -19.05%, rgba(0, 117, 255, 0.00) 216.67%));
}

.box-category-bg {
  width: 220px;
  height: 112px;
  border-radius: 16px;
  border: 1px solid #FFF;
  background: var(--Main, linear-gradient(124deg, rgba(24, 29, 60, 0.04) -22.38%, rgba(255, 255, 255, 0.08) 70.38%));
  display: flex;
  justify-content: center;
  align-items: center;
}

.games-category {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

/* section-5 */
.icon-bank {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.icon-bank img {
  width: 50px;
  height: 50px;
}

img.payment-set {
  max-width: 451px;
  width: 100%;
}

img.section-5-logo {
  max-width: 360px;
  width: 100%;
}

.section-5-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
  color: #fff;
  font-size: 20px;
}


.box-bank {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.underline {
  width: 100%;
  max-width: 1280px;
  height: 1px;
  background: #A4A4A4;
}

.section-5 {
  background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/bg-section-5.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 583px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {

  .menu-flex {
    justify-content: flex-start;
    overflow: auto;
    padding: 0rem 3rem;
  }

  span.text-tital-font1 {
    font-size: 140px;
  }

  span.text-tital-font2 {
    font-size: 180px;
  }

}

@media screen and (max-width: 900px) {


  span.text-tital-font1 {
    font-size: 72px;
  }

  span.text-tital-font2 {
    font-size: 112px;
  }

  .section-1 {
    background-image: url(https://fs.cdnxn.com/landing-sbo55/Images/bg-section-mb1.png);
    height: 760px;
  }

  img.tital-bg-left {
    padding-right: 7rem;
    /* transform: translate(0px, -70%); */
    top: 10%;
  }

  img.tital-bg-right {
    padding-left: 5rem;
    /* transform: translate(0px, -65%); */
    top: 15%;
  }

  .tital-bg {
    justify-content: flex-end;
    height: 80%;
  }

  .menu-flex {
    margin-bottom: 5rem;
  }

  img.section-2-img2 {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 276px;
    height: 202px;
    transform: translate(50%, 120%);
  }

  .section-2-box {
    display: block;
    height: 600px;
  }

  .section-2-img1-mb {
    display: block;
    max-width: 273.194px;
    width: 100%;
    margin: -7rem auto 0rem auto;
  }

  .section-2-img1,
  .section-2-img3 {
    display: none;
  }

  .section-2-box-text {
    transform: translate(0px, 0px);
    padding: 2.5rem 1.5rem 5rem 1.5rem;
  }

  img.section-2-img3-mb {
    position: absolute;
    width: 100%;
    max-width: 140px;
    right: 0rem;
    bottom: -20rem;
  }

  .section-2-img3-mb {
    display: block;
  }

  .box-text-section-2 {
    height: 400px;
    overflow: auto;
  }

  .promotion-banne .custom-slider .splide__slide img {
    width: 85% !important;
    padding: 1rem;
  }

  .section-3-text-promotion {
    padding-top: 10rem;
  }

  .section-3-promotion {
    margin-top: 2rem;
  }

  .games-box.container-section {
    flex-direction: column;
  }

  .games-category {
    flex-direction: unset;
    justify-content: space-between;
    gap: 0rem;
    margin: auto;
    border-radius: 12px;
    border: 1px solid var(--Stork01, #FFF);
    background: var(--Main, linear-gradient(124deg, rgba(24, 29, 60, 0.04) -22.38%, rgba(255, 255, 255, 0.08) 70.38%));
    backdrop-filter: blur(60px);
    padding: 0.5rem;
    width: 100%;
  }

  .box-category-bg {
    width: 74px;
    height: 74px;
    border-radius: 16px;
    border: 0px solid #ffffff00;
    background: var(--Main, linear-gradient(124deg, rgba(24, 29, 60, 0.04) -22.38%, rgb(255 255 255 / 0%) 70.38%));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .category {
    flex-direction: column;
    width: 74px;
    height: 74px;
    background: var(--Main, linear-gradient(124deg, rgb(24 29 60 / 0%) -22.38%, rgb(255 255 255 / 0%) 70.38%));
  }

  .category span {
    font-size: 14px;
  }

  .category .category-game,
  .category .category-slot,
  .category .category-lotto,
  .category .category-casino,
  .category .category-sport {
    max-width: 44px;
    max-height: 44px;
  }

  .games-list {
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    gap: 15px;
  }

  .section-4-text-promotion {
    height: 170px;
  }

  .icon-bank img {
    width: 24px;
    height: 24px;
  }

  icon-bank {
    gap: 10px;
  }

  .section-5-box {
    font-size: 14px;
  }

  .promotion-banner button.splide__arrow.splide__arrow--prev {
    left: 0%;
  }

  .promotion-banner button.splide__arrow.splide__arrow--next {
    right: 0%;
  }

  span.text-tital-section-2 {
    font-size: 24px !important;
  }

  .box-text-section-2 span {
    font-size: 14px;
  }

  span.section-text-head1 {
    font-size: 16px;
  }

  span.section-text-head2 {
    font-size: 36px;
  }

  .promotion-banner-mini {
    display: block;
    margin-top: 2rem;
  }

  .menu-flex-mini {
    display: block;
    padding: 0rem 1rem;
    z-index: 2;
  }

  .menu-flex {
    display: none;
  }

  .bg-menu-flex {
    align-items: flex-start;
    height: 130px;
  }

  .promotion-banner .custom-slider .splide__slide {
    margin: 0px !important;
    width: calc(100%) !important;
  }

  .promotion-banner .custom-slider .splide__slide.is-active {
    margin: 0px !important;
    width: calc(100%) !important;
  }

  .promotion-banner-mini .custom-slider .splide__slide {
    margin-right: 10px !important;
    width: calc(50% - 5px) !important;
  }

  .promotion-banner-mini .custom-slider .splide__slide.is-active {
    margin-right: 10px !important;
    width: calc(50% - 5px) !important;
  }

  .section-login a {
    width: 100%;
  }

  .section-5 {
    margin-bottom: 5rem;
  }

}



@media screen and (max-width: 560px) {
  span.text-tital-font2 {
    font-size: 80px;
  }

  span.text-tital-font1 {
    font-size: 60px;
    margin: 0 auto 1rem;
  }
}

@media screen and (max-width: 390px) {
  .category {
    width: 65px;
    height: 65px;
  }

  .box-category-bg {
    width: 65px;
    height: 65px;
  }

  .section-logo {
    font-size: 11px;
  }

  span.text-tital-font2 {
    font-size: 72px;
  }
}


@media screen and (max-width: 375px) {
  img.section-2-img2 {
    transform: translate(50%, 130%);
  }
}