.wrapper.wrapper--bg {
  /* background: url("https://fs.cdnxn.com/landingSbo55/Images/ImagePC/bg-desktop.png") no-repeat; */
  /* background: url("https://fs.cdnxn.com/landingSbo55/Images/sbo-desktop1.png") no-repeat; */
  background: linear-gradient(180deg, #181D3C 0%, #0A0E23 100%);
  background-size: 100% 100%;
  height: 100vh;
  max-width: 100%;
  background-position: center;
  position: fixed;
  width: 100%;
  overflow-y: auto;
}

@font-face {
  font-family: "sukhumvitSetMedium";
  /*Can be any text*/
  src: local("sukhumvitSet-Medium"),
    url("../Fonts/sukhumvit/ttf/SukhumvitSet-Medium.ttf") format("truetype");
}

* {
  font-family: sukhumvitSetMedium;
}

a {
  color: #fff !important;
  text-decoration: none !important;
}

.header--PC {
  display: block;
}

.header--MB {
  display: none;
}

.mb {
  display: none !important;
}

.pc {
  display: block;
}

.header {
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  border-radius: 20px;
  border: 2px solid #ffffff3b;
  background: linear-gradient(124deg, rgba(255, 255, 255, 0.00) -22.38%, rgba(255, 255, 255, 0.04) 70.38%);
  backdrop-filter: blur(21px);
  max-width: 808px;
  width: 100%;
  height: 66px;
  transform: translate(-50%, 30px);
  left: 50%;
  padding: 0px 1rem;
  ;
}

img.section-head-logo {
  width: 100px;
}

.section-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 2rem;
  cursor: pointer;
}

.section-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #fff;
}

.login-bg {
  border-radius: 12px;
  background: linear-gradient(0deg, rgba(44, 44, 44, 0.30) 0%, rgba(44, 44, 44, 0.30) 100%), #007AFF;
  display: flex;
  width: 120px;
  height: 42px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.register-bg {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.00);
  display: flex;
  width: 120px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.active-text svg path,
.active-text {
  fill: #1767B4;
  color: #1767B4;
}

@media screen and (max-width: 900px) {

  .mb {
    display: block;
  }

  .pc {
    display: none !important;
  }

  img.section-head-logo {
    width: 130px;
  }

  .section-logo {
    gap: 1rem;
    font-size: 14px;
    justify-content: space-around;
    width: 100%;
  }

  .header {
    width: 90%;
    justify-content: center;
  }

  .header.mb {
    bottom: 3rem;
    display: flex !important;
  }

  .section-login {
    justify-content: space-around;
    width: 100%;
  }

  .login-bg,
  .register-bg {
    width: 100%;
  }
}